.btnAcoes{
  display: flex;
  justify-content: start;
  align-items: center;
}
.btnAcoes section{
  width: 150px;
  height: 150px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
}
.btnAcoes section:nth-child(1n) {
  background: #025f05;
}
.btnAcoes section:nth-child(2n) {
  background: #ae0000;
}
.btnAcoes section div:nth-child(2n) {
  color: #fff;
  font-size: 16px;
}
.infoPrivado{
  background: #f7f7f7;
  margin: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.btnView{
  color: #025f05 !important;
  cursor: pointer;
}
.btnView:hover{
  color: #366cf6 !important;
}
.dadosSolicitante{
  background: #f9f9f9;
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.infoSolicitacao{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #fff;
  margin: 5px;
}
.txt-solicitacao{
  background: #f9f9f9;
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.txt{
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
  margin: 5px;
  width: 100%;
}
.txt-dois{
  margin-bottom: 20px;
  background: #f7f7f7;
  padding: 10px;
}
.privado-solicitacao{
  background: #f9f9f9;
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.privado{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #fff;
  margin: 5px;
  width: 100%;
}
.resposta-solicitacao{
  border-left: #366cf6 solid 5px;
  margin-bottom: 10px;
}
.root {
  display: flex;
  background: rgb(245, 248, 255);
}
.content{
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}
.containerPrincipal{
  margin-bottom: 20px;
}
.appBarSpacer{
  padding-top: 40px;
}
.container{
  padding-top: 40px;
  padding-bottom: 40px;

}
.imgMenu{
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 5px;
}
.imgMenu img{
  max-width: 100%;
}
.paper{
  box-shadow: none !important;
  padding: 10px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-height: 200px;
}
.paperSearch{
  box-shadow: none !important;
  display: flex;
  overflow: auto;
  flex-direction: column;
  background: #e5e5e5 !important;
  overflow: hidden;
  padding: 10px;
}
.paperHeader{
  margin-bottom: 10px;
  box-shadow: none !important;
  text-align: center;
  padding: 10px;
}
.paperDashboard{
  box-shadow: none !important;
  padding: 10px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  border: cyan solid 1px;
  border-radius: 10px !important;
  cursor: pointer;
}
.headerPaperTitle{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.paperContant{
  position: relative;
  width: 100%;
  height: 100%;
}
.paperContantLoading{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: rgb(255 255 255 / 72%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.paperContantDois{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.paperConteudo p{
  
}

.textField{
  background: #FFFF;
}

.headerPage{
  display: flex;
  justify-content: space-between;
  justify-items: center;
}
.headerPage h2{
  font-size: calc(0.9em + 0.9vw);
  margin: 0px
}
.button-add{
  font-size: calc(0.6em + 0.6vw);
  background:rgb(63, 1, 121) !important;
  border: none !important;
}
.linkBreadcrumbs{
  font-size: 12px;
}

/* skeleton */
.skelTitlePage{
  width: 30%;
  height: 40px !important;
}

/* table */
.table{
  overflow: hidden;
}
.tableRow{
  border: none !important;
  margin-bottom: 10px !important;
  height: 80px;
}
.tableCell{
  border: none;
}
.modal{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 50%);
  border: none !important;
}
.simple-modal-title{
  background: rgb(1, 124, 52);
  color: #fff;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  padding: 10px;
  font-size: 15px;
}
.simple-modal-conteudo{
  padding: 10px;
}
/* formularios */
.formControl{
  margin-bottom: 10px !important
}

.loading{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: rgb(255 255 255 / 72%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* CSS */
.high-contrast {
  background-color: #000;
  color: #fff;
}

.high-contrast button {
  background-color: #fff !important;
  color: #000;
  border: 2px solid #000;
}
.high-contrast button span svg{
  color: #000 !important
}

.high-contrast .loginUsuario {
  background: red;
}
.high-contrast .sairUsuario{
  background: red;
}
.high-contrast .sairUsuario{
  background: red;
}
.high-contrast .MuiBreadcrumbs-ol , .high-contrast .MuiBreadcrumbs-ol a{
  color: white !important;
}
.high-contrast .MuiDrawer-paper {
  background-color: black;
  border-right: #000;
  color: #fff !important;
}
.high-contrast .MuiDrawer-paper a div span{
  color: #fff !important
}
.high-contrast .MuiDrawer-paper a div svg{
  color: #fff !important
}


.header-topo{
  border-right: 0;
  border-left: 0;
  border-top: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.header-topo-secundary{
  background-color: #0048ab;
  display: flex !important;
  justify-content:space-between;
  align-items: center;
  margin-bottom: 30px;
}
.logo img{
  max-width: 200px;
}
.loginUsuario{
  background: rgb(244, 244, 244);
  padding: 5px;
  border-radius: 10px;
  min-width: 90px;
  position: absolute;
  left: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #025f05 1px solid;
  cursor: pointer;
}
.loginUsuario:hover{
  background: rgb(223, 220, 220);
}
.sairUsuario{
  background: rgb(244, 244, 244);
  padding: 5px;
  border-radius: 10px;
  min-width: 90px;
  position: absolute;
  right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #025f05 1px solid;
  cursor: pointer;
}
.sairUsuario:hover{
  background: rgb(223, 220, 220);
}

.manual{
  width: 100%;
}

.manual div{
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items:center;
  border-radius: 5px;
  font-size: 21px;
  font-weight: bold;
}

.manual div:nth-child(1n) {
  background: #0048ab;
  color: #fff;
}
.manual div:nth-child(2n) {
  background: #0063cc;
  color: #fff;
}
.manual div:hover{
  background: #0048ab;
  cursor: pointer;
}
.paperModal{
  position: absolute;
  width: 60%;
  background: #fff;
  box-shadow: none;
  border: none !important;
}
.formLoginHeader{
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}
.btns{
  width: 100%;
  padding: 5px;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.btnEntrar{
  width: 50%;
  margin: 5px !important;
  /* background-color: #025f05 !important; */
  padding: 10px !important;
}
.customizedInput{
  width: 100%;
  border: #afaeae solid 1px;
  height: 55px;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
}
.paperConteudoChart{
  display: flex;
  justify-content: center;
  align-items: center;
}
.tipoOuvidoria{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  cursor: pointer;
  border-radius: 10px;
}
.ouvUm{
  background: rgb(1, 109, 24);;
}
.ouvDois{
  background: rgb(238, 2, 2);;
}
.ouvTres{
  background: rgb(207, 155, 0);;
}
.ouvQuatro{
  background: rgb(1, 148, 138);;
}
.maskBack{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.682);
  border: solid rgb(136, 2, 2) 3px;
}
.dadosSolicitanteOuvidoria{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.fechaDrawer{
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
}

.tabelaNova{
  width: 100%;
  height: 100%;
}
.itemTabelaHeade{
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itemTabelaHeade .it{
  /* width: 100%; */
  font-size: 11px;
  color: #9eb9d1;
  float: left;
  margin: 10px;
}
.tab{
  position: relative;
  background: linear-gradient(to bottom right, #eef7ff , #e6fdf3);
  margin-bottom: 10px;
  border-radius: 10px;
}
.itemTabela{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itemTabela .it{
  /* width: 100%; */
  margin: 20px 10px 20px 10px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.itemTabela .it:last-child {
  display: flex;
  justify-content: flex-end;
}
.objeto{
  width: 100%;
  padding: 10px;
  margin-top: -28px;
}

.nadaEncontrado{
   background: #f7f7f7;
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 250px;
   margin-top: 20px;
   flex-direction: column;
}

.viewItem{
   background: #f7f7f7;
   padding: 8px;
   border-radius: 10px;
}

.viewItem p{
   margin: 0;
   font-weight: 800;
   color: #02315a;
}

.viewItem span{
   margin: 0;
   font-weight: bold;
   font-size: 17px;
   color: #717171;
}
.files{
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px;
   background: #fff;
   margin-top: 10px;
   border-radius: 10px;
}
.content-view{
   margin-top: 20;
}

.container-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 20px;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
